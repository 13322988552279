import {FormControl, Grid, MenuItem, Select as MuiSelect, styled} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const CustomSelect = styled(MuiSelect)({
    width: '100%',
    fontFamily: 'ALS Sirius',
    fontWeight: '500',
    color: 'black',
    marginTop: '7px !important',
    padding: '1px 10px',
    height: '40px',
    '& .MuiSelect-select.MuiSelect-select': {
        fontFamily: 'ALS Sirius',
        fontWeight: '500',
        color: 'black',
        marginTop: '-5px',
        borderRadius: '16px'
    },
    '& .MuiSelect-select.MuiSelect-select:focus': {
        backgroundColor: 'transparent'
    }
});
export const CustomMenuItem = styled(MenuItem)({
    color: 'black',
    fontFamily: 'ALS Sirius',
    fontWeight: '500',
    '&.MuiListItem-root.Mui-selected': {
        backgroundColor: 'white',
    },
    '&.Mui-focusVisible': {
        backgroundColor: 'white',
    }
});

export const CustomFormControl = styled(FormControl)({
    width: '100%',
    backgroundColor: 'white',
    borderRadius: '12px',
    color: '#9B9B9B',
    maxHeight: '45px',
    '& .MuiFormLabel-root': {
        fontFamily: 'ALS Sirius',
        fontWeight: '500',
        fontSize: '18px',
        color: '#9B9B9B',
        marginTop: '-5px'
    }
});

export const CustomExpandIcon = styled(ExpandMoreIcon)({
    color: '#9B9B9B',
    marginTop: '-15px',
});

export const SearchGrid = styled(Grid)({
    position: 'fixed',
    width: '500px',
    background: 'white',
    marginTop: '-9px',
    zIndex: 1,
    borderTopLeftRadius: '12px',
    borderTopRightRadius: '12px',
});

export const ExpandLessIconActive = styled(ExpandMoreIcon)(({theme}) => ({
    color: theme.palette.error.main,
}));

export const ArrowGrid = styled(Grid)({
    cursor: 'pointer',
    marginLeft: 'auto',
    marginRight: '10px'
});


export const CustomCloseIcon = styled(CloseIcon)({
    color: '#9B9B9B',
});

export const Multiple = styled(Grid)({
    fontFamily: 'ALS Sirius',
    fontWeight: '500',
    fontSize: '16px',
});

export const MultipleBadge = styled(Box)({
    fontFamily: "ALS Sirius",
    fontWeight: 500,
    fontSize: "15px",
    background: "#FA4022",
    width: "25px",
    height: "25px",
    borderRadius: "50%",
    color: "white",
    position: "relative",
    display: "inline-block",
    textAlign: "center",
    marginLeft: "10px"
});