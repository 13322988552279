function endings(count: number, endings: string[]): string {
    let ending: string;
    let i: number;

    if (((count % 100) >= 11) && ((count % 100) <= 19)) {
        ending = endings[2];
    } else {
        i = (count % 100) % 10;
        switch (i) {
            case 1:
                ending = endings[0];
                break;
            case 2:
            case 3:
            case 4:
                ending = endings[1];
                break;
            default:
                ending = endings[2];
        }
    }
    return `${count} ${ending}`;
}

export { endings };