import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import {ThemeProvider} from '@mui/material/styles';
import "./index.css"
import {Provider} from "react-redux";
import {store} from "./App/store";
import theme from "./theme";
import {BrowserRouter} from "react-router-dom";
import Routes from "./App/routes";
import Loading from "./App/components/Loading";
import ruRu from 'rsuite/locales/ru_RU';
import {CustomProvider} from "rsuite";

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement!);

root.render(
    <ThemeProvider theme={theme}>
        <Provider store={store}>
            <CustomProvider locale={ruRu}>
                <CssBaseline/>
                <Loading/>
                <BrowserRouter>
                    <Routes/>
                </BrowserRouter>
            </CustomProvider>
        </Provider>
    </ThemeProvider>,
);
