import React, {useState} from 'react'

import {IFormik} from "../../interface/formik";
import {IconButton, InputLabel} from "@mui/material";
import {Checkbox} from "./Checkbox";
import {
    CustomCloseIcon,
    CustomExpandIcon,
    CustomFormControl,
    CustomMenuItem,
    CustomSelect,
    Multiple,
    MultipleBadge
} from "./styles";

interface SelectProps {
    formik: IFormik
    label: string
    name: string
    options: any[]
    renderValue?: (option: any) => string
}

export const Select = (props: SelectProps) => {
    const {formik, name, renderValue} = props;
    const [display, setDisplay] = useState<boolean>(false)
    const [isResetButtonEnter, setIsResetButtonEnter] = useState<boolean>(false)

    const onMouseEnter = ():void => {
        setIsResetButtonEnter(true)
    }
    const onMouseLeave = ():void  => {
        setIsResetButtonEnter(false)
    }

    const renderDefault = (value: any[]): any => {
        const length: number = value.length;
        switch (true) {
            case length === 1:
                return  renderValue ? renderValue(value[0]) : value[0].name;
            case length > 1:
                return <Multiple>
                    {props.label}
                    <MultipleBadge component="span">
                        {length}
                    </MultipleBadge>
                    <IconButton sx={{zIndex: 1001}} aria-label="delete" onMouseEnter={onMouseEnter}
                                onMouseLeave={onMouseLeave}>
                        <CustomCloseIcon/>
                    </IconButton>
                </Multiple>
        }
    }

    return <CustomFormControl>
        <InputLabel id={`select_${name}`}>{props.label}</InputLabel>
        <CustomSelect
            value={formik.values[name]}
            open={display}
            label={props.label}
            labelId={`select_${name}`}
            onOpen={(): void => {
                if (isResetButtonEnter) {
                    setIsResetButtonEnter(false);
                    formik.setFieldValue(name, []);
                } else {
                    setDisplay(true)
                }
            }}
            onClose={() => setDisplay(false)}
            renderValue={(value: any) => renderValue ? renderValue(value) : renderDefault(value)}
            MenuProps={{
                PaperProps: {
                    style: {
                        borderRadius: '16px',
                        width: '500px',
                        maxHeight: '600px',
                        height: 'auto',
                    }
                },
                sx: {
                    "&& .Mui-selected": {
                        backgroundColor: "white"
                    },
                    "&& .Mui-selected:hover": {
                        backgroundColor: "white"
                    },
                },
            }}
            multiple
            variant="standard"
            IconComponent={() => <CustomExpandIcon/>}
            disableUnderline={true}
        >
            {
                props.options.map(option => {
                    const isSelect: boolean = Boolean(formik.values[name].find((item: any): boolean => item.id === option.id));
                    return <CustomMenuItem
                        key={option.id}
                        value={option.key}
                        onClick={(): void => {
                            if (isSelect) {
                                formik.setFieldValue(name, formik.values[name].filter((item: any): boolean => item.id !== option.id))
                            } else {
                                formik.setFieldValue(name, [...formik.values[name], ...[option]])
                            }
                        }}
                    >
                        <Checkbox
                            checked={isSelect}
                            onChange={(): void => {
                                if (isSelect) {
                                    formik.setFieldValue(name, formik.values[name].filter((item: any): boolean => item.id !== option.id))
                                } else {
                                    formik.setFieldValue(name, [...formik.values[name], ...[option]])
                                }
                            }}/>
                        {renderValue ? renderValue(option) : option.name}
                    </CustomMenuItem>
                })
            }
        </CustomSelect>
    </CustomFormControl>
}
