import {IFormik} from "../../interface/formik";
import {Grid, IconButton, InputAdornment, InputLabel} from "@mui/material";
import {Checkbox} from "./Checkbox";
import {TextField} from "./SelectTree/TextField";
import {MagnifyingGlass} from "../Svg/MagnifyingGlass";
import {
    CustomCloseIcon,
    CustomExpandIcon,
    CustomFormControl,
    CustomMenuItem,
    CustomSelect,
    Multiple,
    MultipleBadge,
    SearchGrid
} from "./styles";
import React, {useState} from "react";


interface AutocompleteProps {
    formik: IFormik
    label: string
    name: string
    options: any[]
    search: string
    onSearch: (value: string) => void
    renderValue?: (option: any) => string
}

export const Autocomplete = (props: AutocompleteProps) => {
    const {formik, name, renderValue, search, onSearch} = props;
    const [display, setDisplay] = useState(false)
    const [isResetButtonEnter, setIsResetButtonEnter] = useState<boolean>(false)

    const onMouseEnter = ():void => {
        setIsResetButtonEnter(true)
    }
    const onMouseLeave = ():void  => {
        setIsResetButtonEnter(false)
    }

    const renderDefault = (value: any[]): any => {
        const length: number = value.length;
        switch (true) {
            case length === 1:
                return  renderValue ? renderValue(value[0]) : value[0].name;
            case length > 1:
                return <Multiple>
                    {props.label}
                    <MultipleBadge component="span">
                        {length}
                    </MultipleBadge>
                    <IconButton sx={{zIndex: 1001}} aria-label="delete" onMouseEnter={onMouseEnter}
                                onMouseLeave={onMouseLeave}>
                        <CustomCloseIcon/>
                    </IconButton>
                </Multiple>
        }
    }
    return <CustomFormControl>
        <InputLabel id={`select_${name}`}>{props.label}</InputLabel>
        <CustomSelect
            value={formik.values[name]}
            open={display}
            label={props.label}
            labelId={`select_${name}`}
            onOpen={(): void => {
                if (isResetButtonEnter) {
                    setIsResetButtonEnter(false);
                    formik.setFieldValue(name, []);
                } else {
                    setDisplay(true)
                }
            }}
            onClose={() => setDisplay(false)}
            renderValue={(value: any) => renderDefault(value)}
            MenuProps={{
                PaperProps: {
                    style: {
                        borderRadius: '16px',
                        width: '500px',
                        height: '600px'
                    }
                },
                sx: {
                    "&& .Mui-selected": {
                        backgroundColor: "white"
                    },
                    "&& .Mui-selected:hover": {
                        backgroundColor: "white"
                    },
                },
            }}
            variant="standard"
            IconComponent={() => <CustomExpandIcon/>}
            disableUnderline={true}
        >
            <SearchGrid
                container
                justifyContent={'center'}
                alignItems={'center'}
            >
                <Grid item xs={11.5}>
                    <TextField
                        name="search"
                        placeholder={`Поиск по названию`}
                        value={search}
                        onChange={onSearch}
                        inputProps={{
                            startAdornment: (
                                <InputAdornment position="start" style={{marginLeft: '15px'}}>
                                    <MagnifyingGlass/>
                                </InputAdornment>
                            ),
                            disableUnderline: true
                        }}
                    />
                </Grid>
            </SearchGrid>
            <Grid style={{marginTop: '50px'}}>
                {
                    props.options.map(option => {
                        const isSelect = Boolean(formik.values[name].find((item: any) => item.id === option.id));
                        return <CustomMenuItem
                            key={option.id}
                            value={option.key}
                            onClick={() => {
                                if (isSelect) {
                                    formik.setFieldValue(name, formik.values[name].filter((item: any) => item.id !== option.id))
                                } else {
                                    formik.setFieldValue(name, [...formik.values[name], ...[option]])
                                }
                            }}
                        >
                            <Checkbox
                                checked={isSelect}
                                onChange={() => {
                                }}/>
                            {renderValue ? renderValue(option) : option.name}

                        </CustomMenuItem>
                    })
                }
            </Grid>
        </CustomSelect>
    </CustomFormControl>
}
