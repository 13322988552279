const getKey = (key: string) => key;
export const has = (key: string): boolean => localStorage.getItem(getKey(key)) !== null;

export const set = (key: string, value: string) => localStorage.setItem(getKey(key), value);

export const get = (key: string): string | null => localStorage.getItem(getKey(key));

export const remove = (key: string) => localStorage.removeItem(getKey(key));

export const clear = () => localStorage.clear();
