import {createAsyncThunk} from "@reduxjs/toolkit";
import {IStatusesResponse} from "../interface/status";
import {IConductionResponse} from "../interface/conductionMethod";
import {ICategoriesResponse, ICategoriesTreeResponse} from "../interface/category";
import {ICustomersQuery, ICustomersResponse} from "../interface/customer";
import {AppService} from "../services/app";

export const fetchStatuses = createAsyncThunk<IStatusesResponse, void, { rejectValue: string }>(
    "fetchStatuses",
    async (_, thunkAPI) => {
        try {
            return await AppService.getStatuses();
        } catch (error) {
            return thunkAPI.rejectWithValue("Ошибка при загрузке статусов!");
        }
    }
);


export const fetchConductionMethods = createAsyncThunk<IConductionResponse, void, {
    rejectValue: string
}>(
    "fetchConductionMethods",
    async (_, thunkAPI) => {
        try {
            return await AppService.getConductionMethods();
        } catch (error) {
            return thunkAPI.rejectWithValue("Ошибка при загрузке способов проведения!");
        }
    }
);


export const fetchCategoriesTree = createAsyncThunk<ICategoriesTreeResponse, void, {
    rejectValue: string
}>(
    "fetchCategoriesTree",
    async (_, thunkAPI) => {
        try {
            return await AppService.getCategoriesTree();
        } catch (error) {
            return thunkAPI.rejectWithValue("Ошибка при загрузке категорий!");
        }
    }
);

export const fetchCategories = createAsyncThunk<ICategoriesResponse, void, {
    rejectValue: string
}>(
    "fetchCategories",
    async (_, thunkAPI) => {
        try {
            return await AppService.getCategories();
        } catch (error) {
            return thunkAPI.rejectWithValue("Ошибка при загрузке категорий!");
        }
    }
);
export const fetchCustomers = createAsyncThunk<ICustomersResponse, ICustomersQuery, {
    rejectValue: string
}>(
    "fetchCustomers",
    async (credentials, thunkAPI) => {
        try {
            return await AppService.getCustomers(credentials);
        } catch (error) {
            return thunkAPI.rejectWithValue("Ошибка при загрузке заказчиков!");
        }
    }
);