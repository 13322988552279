import React from 'react'

export const MagnifyingGlass = () => <svg
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <path d="M22 21.5L16.3191 16.3917" stroke="#9B9B9B" strokeWidth="2"/>
    <circle cx="9.94163" cy="10.4434" r="8.45384" transform="rotate(-3.0383 9.94163 10.4434)" stroke="#9B9B9B"
            strokeWidth="2"/>
</svg>
