import React from "react";
import {useRoutes} from "react-router";
import {Guest} from "../Guest/Guest";

export default function Routes(): JSX.Element {
    const routing = useRoutes([
        {path: 'new', element: <Guest/>},
    ]);

    return <React.Fragment>{routing}</React.Fragment>;
}
