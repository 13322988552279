import {createSlice} from '@reduxjs/toolkit'
import {IAppState} from "../interface/app";
import {
    fetchCategories,
    fetchCategoriesTree,
    fetchConductionMethods,
    fetchCustomers,
    fetchStatuses
} from "../actions/app";
import {fetchPurchaseItems, fetchPurchases} from "../../Guest/actions/guest";
import {IStatus} from "../interface/status";

const initialState: IAppState = {
    statuses: [],
    conductionMethods: [],
    customers: [],
    categoriesTree: [],
    categories: [],
    loading: false,
}
export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {},
    extraReducers: (builder): void => {
        builder
            .addCase(fetchStatuses.pending, (state): void => {
                state.loading = true;
            })
            .addCase(fetchStatuses.fulfilled, (state, action): void => {
                state.loading = false;
                state.statuses = action.payload.status.filter((item: IStatus): boolean => item.index !== 0);
            })
            .addCase(fetchStatuses.rejected, (state): void => {
                state.loading = false;
            })
            .addCase(fetchConductionMethods.pending, (state): void => {
                state.loading = true;
            })
            .addCase(fetchConductionMethods.fulfilled, (state, action): void => {
                state.loading = false;
                state.conductionMethods = action.payload.conductionMethods;
            })
            .addCase(fetchConductionMethods.rejected, (state): void => {
                state.loading = false;
            })
            .addCase(fetchCategoriesTree.pending, (state): void => {
                state.loading = true;
            })
            .addCase(fetchCategoriesTree.fulfilled, (state, action): void => {
                state.loading = false;
                state.categoriesTree = action.payload.categories;
            })
            .addCase(fetchCategoriesTree.rejected, (state): void => {
                state.loading = false;
            })
            .addCase(fetchCategories.pending, (state): void => {
                state.loading = true;
            })
            .addCase(fetchCategories.fulfilled, (state, action): void => {
                state.loading = false;
                state.categories = action.payload.categories;
            })
            .addCase(fetchCategories.rejected, (state): void => {
                state.loading = false;
            })
            .addCase(fetchCustomers.pending, (state): void => {
                state.loading = true;
            })
            .addCase(fetchCustomers.fulfilled, (state, action): void => {
                state.loading = false;
                state.customers = action.payload.data;
            })
            .addCase(fetchCustomers.rejected, (state): void => {
                state.loading = false;
            })
            .addCase(fetchPurchases.pending, (state): void => {
                state.loading = true;
            })
            .addCase(fetchPurchases.fulfilled, (state): void => {
                state.loading = false;
            })
            .addCase(fetchPurchases.rejected, (state): void => {
                state.loading = false;
            })
            .addCase(fetchPurchaseItems.pending, (state): void => {
                state.loading = true;
            })
            .addCase(fetchPurchaseItems.fulfilled, (state): void => {
                state.loading = false;
            })
            .addCase(fetchPurchaseItems.rejected, (state): void => {
                state.loading = false;
            })
    },
})