import React from 'react'
import {styled, TextField as MuiTextField} from "@mui/material";
import {IFormik} from "../../interface/formik";

const CustomTextField = styled(MuiTextField)({
    background: 'white',
    border: 'none',
    borderRadius: '12px',
    padding: '5px 10px 5px 10px',
    fontWeight: 500,
    maxHeight: '45px',
    '& .MuiInputBase-input-MuiInput-input': {
        fontWeight: 'bold'
    }
});

interface TextFieldProps {
    name: string
    type?: string
    placeholder: string
    inputProps?: {
        startAdornment?: JSX.Element,
        disableUnderline?: boolean
    },
    onChange?: (value: string) => void
    formik: IFormik
}

export const TextField = (props: TextFieldProps) => <CustomTextField
    name={props.name}
    value={props.formik.values[props.name]}
    placeholder={props.placeholder}
    onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
        const value: string = props?.type === 'number' ? event.target.value.toString().replaceAll(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ",") : event.target.value;
        if (props.onChange) {
            props.onChange(value)
        } else {
            props.formik.setFieldValue(props.name, value)
        }
    }}
    variant="standard"
    fullWidth={true}
    margin={'normal'}
    InputProps={{
        style: {
            fontFamily: 'ALS Sirius',
            fontWeight: 'normal',
            fontSize: '18px',
            color: 'black'
        },
        startAdornment: props?.inputProps?.startAdornment ?? null,
        disableUnderline: props?.inputProps?.disableUnderline ?? true,
    }}
>
</CustomTextField>
