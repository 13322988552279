import React from "react"

interface FiltersProps {
    color: string
}
export const Filters = (props:FiltersProps ) => <svg width="24" height="24" viewBox="0 0 24 24" fill={props.color} xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M3.5998 6.79983H4.01963C4.17833 7.29565 4.48335 7.7334 4.89629 8.0544C5.35058 8.40755 5.90959 8.59927 6.485 8.59927C7.06042 8.59927 7.61943 8.40755 8.07372 8.0544C8.48666 7.7334 8.79168 7.29565 8.95038 6.79983H20.3998C20.612 6.79983 20.8155 6.71554 20.9655 6.56551C21.1155 6.41549 21.1998 6.212 21.1998 5.99983C21.1998 5.78766 21.1155 5.58417 20.9655 5.43414C20.8155 5.28411 20.612 5.19983 20.3998 5.19983H8.95038C8.79168 4.70401 8.48666 4.26626 8.07372 3.94526C7.61943 3.59211 7.06042 3.40039 6.485 3.40039C5.90959 3.40039 5.35058 3.59211 4.89629 3.94526C4.48335 4.26626 4.17833 4.70401 4.01963 5.19983H3.5998C3.38763 5.19983 3.18415 5.28411 3.03412 5.43414C2.88409 5.58417 2.7998 5.78766 2.7998 5.99983C2.7998 6.212 2.88409 6.41549 3.03412 6.56551C3.18415 6.71554 3.38763 6.79983 3.5998 6.79983ZM8.79999 6.59983C8.79998 6.59985 8.79999 6.59981 8.79999 6.59983V6.59983ZM6.485 4.99983C6.68279 4.99983 6.87613 5.05848 7.04057 5.16836C7.20502 5.27824 7.3332 5.43442 7.40888 5.61715C7.48457 5.79987 7.50438 6.00094 7.46579 6.19492C7.4272 6.3889 7.33196 6.56708 7.19211 6.70694C7.05226 6.84679 6.87408 6.94203 6.6801 6.98061C6.48611 7.0192 6.28505 6.9994 6.10232 6.92371C5.9196 6.84802 5.76342 6.71985 5.65354 6.5554C5.54365 6.39095 5.485 6.19761 5.485 5.99983C5.485 5.73461 5.59036 5.48026 5.7779 5.29272C5.96543 5.10519 6.21979 4.99983 6.485 4.99983Z"
          fill={props.color}/>
    <path fillRule="evenodd" clipRule="evenodd"
          d="M20.3998 11.1998H19.3504C19.1917 10.704 18.8867 10.2663 18.4737 9.94526C18.0194 9.59211 17.4604 9.40039 16.885 9.40039C16.3096 9.40039 15.7506 9.59211 15.2963 9.94526C14.8833 10.2663 14.5783 10.704 14.4196 11.1998H3.5998C3.38763 11.1998 3.18415 11.2841 3.03412 11.4341C2.88409 11.5842 2.7998 11.7877 2.7998 11.9998C2.7998 12.212 2.88409 12.4155 3.03412 12.5655C3.18415 12.7155 3.38763 12.7998 3.5998 12.7998H14.4196C14.5783 13.2957 14.8834 13.7334 15.2963 14.0544C15.7506 14.4075 16.3096 14.5993 16.885 14.5993C17.4604 14.5993 18.0194 14.4075 18.4737 14.0544C18.8867 13.7334 19.1917 13.2957 19.3504 12.7998H20.3998C20.612 12.7998 20.8155 12.7155 20.9655 12.5655C21.1155 12.4155 21.1998 12.212 21.1998 11.9998C21.1998 11.7877 21.1155 11.5842 20.9655 11.4341C20.8155 11.2841 20.612 11.1998 20.3998 11.1998ZM20.4 12.5998H19.2C19.2 12.5998 19.2 12.5998 19.2 12.5998H20.4ZM16.885 12.9998C16.6872 12.9998 16.4939 12.9412 16.3294 12.8313C16.165 12.7214 16.0368 12.5652 15.9611 12.3825C15.8854 12.1998 15.8656 11.9987 15.9042 11.8047C15.9428 11.6108 16.038 11.4326 16.1779 11.2927C16.3178 11.1529 16.4959 11.0576 16.6899 11.019C16.8839 10.9805 17.085 11.0003 17.2677 11.0759C17.4504 11.1516 17.6066 11.2798 17.7165 11.4443C17.8264 11.6087 17.885 11.802 17.885 11.9998C17.885 12.265 17.7796 12.5194 17.5921 12.7069C17.4046 12.8945 17.1502 12.9998 16.885 12.9998Z"
          fill={props.color}/>
    <path fillRule="evenodd" clipRule="evenodd"
          d="M20.9655 17.4341C20.8155 17.2841 20.612 17.1998 20.3998 17.1998H14.4652C14.3065 16.704 14.0015 16.2663 13.5885 15.9453C13.1342 15.5921 12.5752 15.4004 11.9998 15.4004C11.4244 15.4004 10.8654 15.5921 10.4111 15.9453C9.99815 16.2663 9.69313 16.704 9.53443 17.1998H3.5998C3.38763 17.1998 3.18415 17.2841 3.03412 17.4341C2.88409 17.5842 2.7998 17.7877 2.7998 17.9998C2.7998 18.212 2.88409 18.4155 3.03412 18.5655C3.18415 18.7155 3.38763 18.7998 3.5998 18.7998H9.53443C9.69313 19.2956 9.99815 19.7334 10.4111 20.0544C10.8654 20.4075 11.4244 20.5993 11.9998 20.5993C12.5752 20.5993 13.1342 20.4075 13.5885 20.0544C14.0015 19.7334 14.3065 19.2956 14.4652 18.7998H20.3998C20.612 18.7998 20.8155 18.7155 20.9655 18.5655C21.1155 18.4155 21.1998 18.212 21.1998 17.9998C21.1998 17.7877 21.1155 17.5842 20.9655 17.4341ZM20.4 18.5998H14.3148C14.3148 18.5998 14.3148 18.5998 14.3148 18.5998H20.4ZM11.9998 18.9998C11.802 18.9998 11.6087 18.9412 11.4442 18.8313C11.2798 18.7214 11.1516 18.5652 11.0759 18.3825C11.0002 18.1998 10.9804 17.9987 11.019 17.8047C11.0576 17.6108 11.1528 17.4326 11.2927 17.2927C11.4326 17.1529 11.6107 17.0576 11.8047 17.019C11.9987 16.9805 12.1998 17.0003 12.3825 17.0759C12.5652 17.1516 12.7214 17.2798 12.8313 17.4443C12.9412 17.6087 12.9998 17.802 12.9998 17.9998C12.9998 18.265 12.8944 18.5194 12.7069 18.7069C12.5194 18.8945 12.265 18.9998 11.9998 18.9998Z"
          fill={props.color}/>
</svg>




