import {configureStore} from "@reduxjs/toolkit"
import {guestSlice} from "../Guest/slices/guest";
import {appSlice} from "./slices/app";

export const store = configureStore({
    reducer: {
        app: appSlice.reducer,
        guest: guestSlice.reducer,
    }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch