import {createTheme} from '@mui/material/styles';

const RED = '#FA4022';

const theme = createTheme({
  palette: {
    primary: {
      main: '#9B9B9B',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: RED,
    },
  },
  components: {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: 'black',
          fontFamily: "ALS Sirius, Helvetica, sans-serif",
          lineHeight: "24px",
          fontWeight: 500,
          '&.Mui-focusVisible': {
            color: RED,
            backgroundColor: 'white !important'
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        select: {
          color: 'black',
          fontFamily: "ALS Sirius, Helvetica, sans-serif",
          lineHeight: "24px",
          fontWeight: 500,
        },
        selectLabel: {
          color: 'black',
          fontFamily: "ALS Sirius, Helvetica, sans-serif",
          lineHeight: "24px",
          fontWeight: 500,
          fontSize: '15px'
        },
        displayedRows: {
          color: 'black',
          fontFamily: "ALS Sirius, Helvetica, sans-serif",
          lineHeight: "24px",
          fontWeight: 500,
          fontSize: '15px'
        }
      }
    }
  }
});

export default theme;
