import * as AppStorage from '../helpers/storage';
import axios, {AxiosError, AxiosResponse, RawAxiosRequestConfig} from "axios";

export const HttpService = {
    http,
    get,
}

interface IErrorResponse extends AxiosResponse {
    data: {
        error: {
            message: string,
            errors: string[]
        }
    }
}

interface IError extends AxiosError {
    response: IErrorResponse
}

async function http(location: string, options: RawAxiosRequestConfig) {
    const config: RawAxiosRequestConfig = {
        url: process.env.REACT_APP_API_URL + location,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        ...options
    }

    return axios.request(config).then(
        (response: any) => handleResponse(response, options),
        (error: IError) => handleError(error));
}

function handleError(response: IError) {
    if (response.response) {
        throw response.response.data?.error ?? response.response.data
    }
    throw response
}

function handleResponse(response: AxiosResponse, options: RawAxiosRequestConfig) {
    let data

    switch (response.status) {
        case 204:
            return response
        case 304:
            return response
        case 401:
            AppStorage.clear()
            window.location.href = "/login"
            break
        case 409:
        case 422:
            return response.data.error
        default:
            data = response.data
    }
    if (!response.status.toString().startsWith('2')) {
        return data ? data.then(Promise.reject.bind(Promise)) : response
    }

    return data
}

async function get(location: string, params = {}) {
    const options: RawAxiosRequestConfig = {
        ...{
            method: "GET",
            params: params
        },
    }
    return await HttpService.http(location, options);
}