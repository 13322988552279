import {createSlice} from '@reduxjs/toolkit'
import {IGuestState} from "../interface/guest";
import {fetchPurchaseItems, fetchPurchases} from "../actions/guest";

const initialState: IGuestState = {
    purchases: {
        data: [],
        links: {
            first: '',
            last: '',
            prev: '',
            next: ''
        },
        meta: {
            current_page: 1,
            from: 1,
            last_page: 1,
            links: [],
            path: '',
            per_page: 1,
            to: 1,
            total: 1
        }
    },
}
export const guestSlice = createSlice({
    name: 'guest',
    initialState,
    reducers: {},
    extraReducers: (builder): void => {
        builder
            .addCase(fetchPurchases.fulfilled, (state, action): void => {
                state.purchases = action.payload;
            })
            .addCase(fetchPurchaseItems.fulfilled, (state, action): void => {
                const purchaseIndex: number = state.purchases.data.findIndex((item): boolean => item.id === action.payload.orderId);
                if (purchaseIndex !== -1) {
                    let purchase = state.purchases.data[purchaseIndex];
                    purchase.lots = purchase.lots.map((lot) => {
                        if (lot.id === action.payload.lotId) {
                            lot.items = action.payload.data;
                        }
                        return lot;
                    })
                    state.purchases.data[purchaseIndex] = purchase;
                }
            })
    },
})