import React, {useState} from 'react'
import {IFormik} from "../../interface/formik";
import {Grid, IconButton, InputAdornment, InputLabel} from "@mui/material";
import {Checkbox} from "./Checkbox";
import {TextField} from "./SelectTree/TextField";
import {MagnifyingGlass} from "../Svg/MagnifyingGlass";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    ArrowGrid,
    CustomCloseIcon,
    CustomFormControl,
    CustomMenuItem,
    CustomSelect,
    ExpandLessIconActive,
    Multiple,
    MultipleBadge,
    SearchGrid
} from "./styles";


const MAX_TITLE_LENGTH = 40;
const truncateTitle = (title: string): string => {
    if (title.length > MAX_TITLE_LENGTH) {
        return title.substring(0, MAX_TITLE_LENGTH) + '...';
    }
    return title;
};

interface SelectProps {
    formik: IFormik
    label: string
    name: string
    options: any[]
    renderValue?: (option: any) => string
}

interface IOption {
    id: number
    parents: number[]
}

function CustomExpandIcon() {
    return null;
}

export const SelectTree = (props: SelectProps) => {
    const {formik, name, renderValue, options} = props;
    const [display, setDisplay] = useState<boolean>(false)
    const [openOptions, setOpenOptions] = useState<number[]>([]);
    const [parentOptions, setParentOptions] = useState<IOption[]>([]);
    const [search, setSearch] = useState<string>('');
    const [isResetButtonEnter, setIsResetButtonEnter] = useState<boolean>(false)

    const onMouseEnter = ():void => {
        setIsResetButtonEnter(true)
    }
    const onMouseLeave = ():void  => {
        setIsResetButtonEnter(false)
    }
    const handleOptionToggle = (optionId: number): void => {
        if (openOptions.includes(optionId)) {
            setOpenOptions(openOptions.filter((id: number): boolean => id !== optionId));
        } else {
            setOpenOptions([...openOptions, optionId]);
        }
    };

    const renderDefault = (value: any[]): any => {
        const length: number = value.length;
        switch (true) {
            case length === 1:
                return  renderValue ? renderValue(value[0]) : value[0].name;
            case length > 1:
                return <Multiple>
                    {props.label}
                    <MultipleBadge component="span">
                        {length}
                    </MultipleBadge>
                    <IconButton sx={{zIndex: 1001}} aria-label="delete" onMouseEnter={onMouseEnter}
                                onMouseLeave={onMouseLeave}>
                        <CustomCloseIcon/>
                    </IconButton>
                </Multiple>
        }
    }

    const isOptionOpen = (optionId: number) => openOptions.includes(optionId);
    const renderOptions = (options: any[]) => options.map(option => {
        const isSelect: boolean = Boolean(formik.values[name].find((item: any): boolean => item.id === option.id));
        const isIndeterminate: boolean = parentOptions.findIndex((item: IOption) => item.parents?.includes(option.id)) !== -1;
        return <React.Fragment key={option.id}>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <CustomMenuItem
                    key={option.id}
                    value={option.key}
                    style={{color: isSelect ? '#FA4022' : 'black'}}
                    onClick={(): void => {
                        if (isSelect) {
                            setParentOptions(parentOptions.filter((item: IOption): boolean => item.id !== option.id))
                            formik.setFieldValue(name, formik.values[name].filter((item: any): boolean => item.id !== option.id))
                        } else {
                            setParentOptions((prevParentOptions: IOption[]) => [
                                ...prevParentOptions,
                                ...[{
                                    id: option.id,
                                    parents: option.parents
                                }]
                            ]);
                            formik.setFieldValue(name, [...formik.values[name], ...[option]])
                        }
                    }}
                >
                    <Checkbox
                        indeterminate={isIndeterminate}
                        checked={isSelect}
                        onChange={(): void => {
                        }}/>
                    {truncateTitle(renderValue ? renderValue(option) : option.name)}
                </CustomMenuItem>
                {option.childrens && option.childrens.length > 0 && (
                    <ArrowGrid
                        onClick={() => handleOptionToggle(option.id)}
                    >
                        {isOptionOpen(option.id) ? <ExpandLessIconActive/> : <ExpandMoreIcon/>}
                    </ArrowGrid>
                )}
            </div>
            {isOptionOpen(option.id) && option.childrens && option.childrens.length > 0 && (
                <div style={{marginLeft: 20}}>
                    {renderOptions(option.childrens)}
                </div>
            )}
        </React.Fragment>
    });

    return <CustomFormControl>
        <InputLabel id={`select_${name}`}>{props.label}</InputLabel>
        <CustomSelect
            value={formik.values[name]}
            open={display}
            label={props.label}
            labelId={`select_${name}`}
            onOpen={(): void => {
                if (isResetButtonEnter) {
                    setIsResetButtonEnter(false);
                    formik.setFieldValue(name, []);
                } else {
                    setDisplay(true)
                }
            }}
            onClose={() => setDisplay(false)}
            renderValue={(value: any) => renderValue ? renderValue(value) : renderDefault(value)}
            MenuProps={{
                PaperProps: {
                    style: {
                        borderRadius: '16px',
                        width: '500px',
                        height: '600px'
                    }
                },
                sx: {
                    "&& .Mui-selected": {
                        backgroundColor: "white"
                    },
                    "&& .Mui-selected:hover": {
                        backgroundColor: "white"
                    },
                },
            }}
            variant="standard"
            IconComponent={() => <CustomExpandIcon/>}
            disableUnderline={true}
        >
            <SearchGrid
                container
                justifyContent={'center'}
                alignItems={'center'}
            >
                <Grid item xs={11.5}>
                    <TextField
                        name="search"
                        placeholder={`Поиск по названию`}
                        value={search}
                        onChange={setSearch}
                        inputProps={{
                            startAdornment: (
                                <InputAdornment position="start" style={{marginLeft: '15px'}}>
                                    <MagnifyingGlass/>
                                </InputAdornment>
                            ),
                            disableUnderline: true
                        }}
                    />
                </Grid>
            </SearchGrid>
            <Grid style={{marginTop: '50px'}}>
                {
                    renderOptions(props.options)
                }
            </Grid>
        </CustomSelect>
    </CustomFormControl>
}
