import {Checkbox as MuiCheckbox} from "@mui/material";
import React from "react";

interface Props {
    checked: boolean
    onChange: (value: boolean) => void
    indeterminate?: boolean
}

export const Checkbox = (props: Props) => <MuiCheckbox
    checked={props.checked}
    size="small"
    indeterminate={props.checked ? false : props.indeterminate}
    onChange={(event: any) => props.onChange(event.target.checked)}
    style={{
        color: (props.checked || props.indeterminate) ? "#FA4022" : "#E6E6E6",
    }}
/>
