import * as React from "react";

export const getPurchaseStatusCircle = (id: number): JSX.Element => {
    let fill = '#FA4022';
    let stroke = '#FA4022';
    switch (id) {
        case 0:
            fill = '#9B9B9B';
            stroke = '#E6E6E6';
            break;
        case 1:
        case 2:
        case 7:
            fill = '#338C6C';
            stroke = '#ADD1C4';
            break;
        case 3:
        case 6:
        case 8:
            fill = '#1472FF';
            stroke = '#B9D5FF';
            break;
        case 4:
        case 5:
            fill = '#FA4022';
            stroke = '#FA4022';
            break;
    }
    return <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="5" cy="5" r="3" fill={fill}/>
        <circle cx="5" cy="5" r="4" stroke={stroke}/>
    </svg>;
}