import Box from "@mui/material/Box";
import CircularProgress, {circularProgressClasses,} from '@mui/material/CircularProgress';
import Grid from "@mui/material/Grid";
import {styled} from '@mui/material/styles'
import React from 'react'
import {useAppSelector} from "../hooks";

const PREFIX = 'Loading'

const classes = {
    loading: `${PREFIX}-loading`,
}

const Root = styled('div')(() => ({
    [`& .${classes.loading}`]: {
        top: "0",
        left: "0",
        width: "100vw",
        height: "100vh",
        display: "flex",
        position: "fixed",
        alignItems: "center",
        justifyContent: "center",
        background:  "rgba(241,241,241,0.3)",
        zIndex: 1000
    },
}))

type Props = {
    className?: string;
}

export function FacebookCircularProgress(props: Props): JSX.Element {
    return (
        <Box sx={{position: 'relative'}}>
            <CircularProgress
                variant="determinate"
                sx={{
                    color: (theme) => theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
                }}
                size={40}
                thickness={4}
                {...props}
                value={100}
            />
            <CircularProgress
                variant="indeterminate"
                disableShrink
                sx={{
                    zIndex: 1000,
                    color: '#FA4022',
                    animationDuration: '750ms',
                    position: 'absolute',
                    left: 0,
                    [`& .${circularProgressClasses.circle}`]: {
                        strokeLinecap: 'round',
                    },
                }}
                size={40}
                thickness={4}
                {...props}
            />
        </Box>
    );
}

export default function Loading(): JSX.Element | null {
    const loading: boolean = useAppSelector((state) => state.app.loading)

    return loading ? (
        <Root>
            <Grid className={classes.loading}>
                <FacebookCircularProgress/>
            </Grid>
        </Root>
    ) : null
}
