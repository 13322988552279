import {styled, TextField as MuiTextField} from "@mui/material";
import React from "react";

const CustomTextField = styled(MuiTextField)({
    background: 'white',
    border: '2px solid #E6E6E6',
    borderRadius: '12px',
    padding: '3px 5px 3px 5px',
    fontWeight: 500,
    maxHeight: '45px',
    fontFamily: 'ALS Sirius',
    color: '#9B9B9B',
    fontSize: '18px',
    '& .MuiInputBase-input-MuiInput-input': {
        fontWeight: 'bold'
    }
});

interface TextFieldProps {
    name: string
    placeholder: string
    inputProps?: {
        startAdornment?: JSX.Element,
        disableUnderline?: boolean
    },
    value: string
    onChange: (value: string) => void
}

export const TextField = (props: TextFieldProps) => <CustomTextField
    name={props.name}
    value={props.value}
    placeholder={props.placeholder}
    onChange={(event:React.ChangeEvent<HTMLInputElement>): void =>  props.onChange(event.target.value)}
    onClick={(e:any) => e?.stopPropagation()}
    variant="standard"
    fullWidth={true}
    margin={'normal'}
    InputProps={{
        style: {
            fontFamily: 'ALS Sirius',
            fontWeight: 'normal',
            fontSize: '18px',
            color: 'black'
        },
        startAdornment: props?.inputProps?.startAdornment ?? null,
        disableUnderline: props?.inputProps?.disableUnderline ?? true
    }}
/>
