import {createAsyncThunk} from "@reduxjs/toolkit";
import {GuestService} from "../services/guest";
import {IPurchaseItemsQuery, IPurchaseItemsResponse, IPurchasesQuery, IPurchasesResponse} from "../interface/purchase";


export const fetchPurchases = createAsyncThunk<IPurchasesResponse, IPurchasesQuery, {
    rejectValue: string
}>(
    "fetchPurchases",
    async (credentials, thunkAPI) => {
        try {
            return await GuestService.getPurchase(credentials);
        } catch (error) {
            return thunkAPI.rejectWithValue("Ошибка при загрузке закупок!");
        }
    }
);
export const fetchPurchaseItems = createAsyncThunk<IPurchaseItemsResponse, IPurchaseItemsQuery, {
    rejectValue: string
}>(
    "fetchPurchaseItems",
    async (credentials, thunkAPI) => {
        try {
            const res = await GuestService.getItems(credentials);
            return {
                orderId: credentials.orderId,
                lotId: credentials.lotId,
                data: res.data
            };
        } catch (error) {
            return thunkAPI.rejectWithValue("Ошибка при загрузке закупок!");
        }
    }
);